<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template v-slot:cell(QRCodeDevice)="value">
        <div v-if="value.item.approved">
          <b-media vertical-align="center">
            <span v-if="value.item.status === 'offline'" />
            <QRCodeDevice
              v-else
              :style="`margin:'10px 0px'`"
              :item-id="value.item.unique_id"
              :item-name="value.item.name"
              :item-serial-number="value.item.gulftic_device_id"
            />
          </b-media>
        </div>
      </template>
      <template #cell(image_device)="data">
        <div
          v-if="data.item.photo"
          style="width: 100px; height: 100px;"
        >
          <img
            :src="data.item.photo"
            style="height: 100%; max-width: 100%"
          >
        </div>
        <div v-else>
          <div
            style="width: 80px; height: 80px;"
          >
            <feather-icon
              icon="MonitorIcon"
              style="width: 100%; height: 100%;"
            />
          </div>
        </div>
      </template>
      <template #cell(id)="data">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            :style="
              `color: ${
                data.item.status === 'offline' ? 'red' : 'black'
              };`
            "
          >
            {{ data.item.id }}
          </b-link>
        </b-media>
      </template>
      <template #cell(gulftic_device_id)="data">
        <span
          v-if="data.item.gulftic_device_id"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.gulftic_device_id }}</span>
      </template>
      <template #cell(name)="data">
        <span
          v-if="data.item.name"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.name }}</span>
      </template>
      <template #cell(brand)="data">
        <span
          v-if="data.item.brand"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.brand }}</span>
      </template>
      <template #cell(model_number)="data">
        <span
          v-if="data.item.model_number"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.model_number }}</span>
      </template>
      <template #cell(serial_number)="data">
        <span
          v-if="data.item.serial_number"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.serial_number }}</span>
      </template>
      <template #cell(purchased_date)="data">
        <span
          v-if="data.item.purchased_date"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.purchased_date }}</span>
      </template>
      <template #cell(assigned_date)="data">
        <span
          v-if="data.item.assigned_date"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.assigned_date }}</span>
      </template>
      <template #cell(notes)="data">
        <span
          v-if="data.item.notes"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.notes }}</span>
      </template>
      <template #cell(status)="data">
        <span
          v-if="data.item.status"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.status }}</span>
      </template>
      <template #cell(maintenance_date)="data">
        <span
          v-if="data.item.maintenance_date"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.maintenance_date }}</span>
      </template>
      <template #cell(over_due)="data">
        <span
          v-if="data.item.over_due"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.over_due }}</span>
      </template>

      <template #cell(employee)="data">
        <span
          v-if="data.item.employee"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.employee.name }}</span>
      </template>
      <template #cell(employee_email)="data">
        <span
          v-if="data.item.employee"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.employee.email }}</span>
      </template>
      <template #cell(employeeJobTitle)="data">
        <span
          v-if="data.item.employee"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.employee.job_title }}</span>
      </template>
      <template #cell(employeeMobile)="data">
        <span
          v-if="data.item.employee"
          class="font-weight-bold d-block text-nowrap"
          :style="
            `color: ${
              data.item.status === 'offline' ? 'red' : ''
            }; padding: ${data.item.status === 'offline' ? '10px' : ''};`
          "
        >{{ data.item.employee.mobile }}</span>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import QRCodeDevice from '@/components/general-table/QRCodeDevices.vue'

export default {
  components: {
    GeneralTable,
    QRCodeDevice,

  },
  data() {
    return {
      APIURL: 'device',
      addType: 'Add Device',
      addComponentName: 'add-device',
      editComponent: 'edit-devices',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'image_device', label: 'image' },
        { key: 'gulftic_device_id', label: 'gulftic_device_id' },
        { key: 'name', label: 'Name' },
        { key: 'brand', label: 'brand' },
        { key: 'model_number', label: 'model_number' },
        { key: 'serial_number', label: 'serial_number' },
        { key: 'purchased_date', label: 'purchased_date' },
        { key: 'branch.name', label: 'branch' },
        { key: 'assigned_date', label: 'assigned_date' },
        { key: 'notes', label: 'notes' },
        { key: 'status', label: 'status' },
        { key: 'employee', label: 'Custodian Name' },
        { key: 'employee_email', label: 'Custodian EMAIL' },
        { key: 'employeeJobTitle', label: 'Custodian JOB TITLE' },
        { key: 'employeeMobile', label: 'Custodian MOBILE' },
        { key: 'QRCodeDevice', label: 'QR Device' },

        // { key: 'maintenance_date', label: 'maintenance_date' },
        // { key: 'over_due', label: 'over_due' },
        { key: 'approved', label: 'approved' },
        // "image": "http://localhost:8000/storage/images/fV5rnECvyiYySzDLBwrWfoORUyEHtazxQEXPszuc.png",
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'device?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
